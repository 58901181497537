import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import Header from '../Header';
import WelcomeSection from '../WelcomeSection';
import QuestionsSection from '../QuestionsSection';
import ResultsSection from '../ResultsSection';
import { options } from '../../data';

const Container = () => {
  const [result, setResult] = useState('basic');
  const [showHeader, setShowHeader] = useState(true);
  const [showWelcome, setShowWelcome] = useState(true);
  const [showQuestions, setShowQuestions] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const screenWidth = window.screen.width; // eslint-disable-line no-undef

  const initialAnimationRef = useRef();
  const mainTimeLineRef = useRef();
  const resultsTimeLineRef = useRef();

  const handleStart = () => {
    mainTimeLineRef.current.play();
  };

  const parseOptions = (selectedOptions, optionsArray) => {
    const optionsTexts = selectedOptions.map(selectedOption => {
      const foundOption = optionsArray.find(option => option.id === selectedOption);
      return foundOption.text;
    });

    // eslint-disable-next-line no-undef
    window.dataLayer = window.dataLayer || [];
    optionsTexts.forEach(element => {
      // eslint-disable-next-line no-undef
      window.dataLayer.push({
        event: 'Quiz Options',
        options: element,
      });
    });
  };

  const handleSubmit = selectedOptions => {
    parseOptions(selectedOptions, Object.values(options));
    selectedOptions.sort((a, b) => a - b);

    let resultText = 'Basic';
    const lastOption = selectedOptions[selectedOptions.length - 1];
    if (lastOption === options.job.id || lastOption === options.children.id) {
      setResult('basic');
    }
    if (
      lastOption === options.home.id ||
      lastOption === options.charity.id ||
      lastOption === options.deductions.id
    ) {
      setResult('deluxe');
      resultText = 'Deluxe no State';
    }
    if (lastOption === options.taxes.id) {
      setResult('deluxeState');
      resultText = 'Deluxe with State';
    }
    if (lastOption === options.stock.id || lastOption === options.rental.id) {
      setResult('premier');
      resultText = 'Premier';
    }
    if (lastOption === options.selfEmployed.id || lastOption === options.business.id) {
      setResult('homeBusiness');
      resultText = 'Home & Business';
    }
    resultsTimeLineRef.current.play();

    // eslint-disable-next-line no-undef
    window.dataLayer.push({
      event: 'Product Results',
      results: resultText,
    });
  };

  const toWindowTop = (withSmooth = true) => {
    const config = {
      top: 0,
      left: 0,
    }
    if (withSmooth) {
      config.behavior = 'smooth';
    }
    // eslint-disable-next-line no-undef
    window.scrollTo(config);
  };

  const handleStartOver = () => {
    toWindowTop();
    initialAnimationRef.current.restart();
    mainTimeLineRef.current.restart();
    mainTimeLineRef.current.pause();
    resultsTimeLineRef.current.restart();
    resultsTimeLineRef.current.pause();
    setShowHeader(true);
    setShowWelcome(true);
    setShowQuestions(false);
    setShowResults(false);
  };

  useEffect(() => {
    initialAnimationRef.current = gsap.to('#welcomeElement', {
      opacity: 1,
      duration: 1,
      ease: 'power1.in',
    });

    const mainTimeLine = gsap.timeline({ paused: true });
    const resultsTimeLine = gsap.timeline({ paused: true });

    mainTimeLine.to('#welcomeNoteElement', {
      opacity: 0,
      duration: 1,
      ease: 'power1.in',
      onComplete: () => setShowQuestions(true),
    });
    mainTimeLine.to('#welcomeButton', {
      y: '50vh',
      duration: 1,
      ease: 'power2.in',
    });
    mainTimeLine.to(
      '#welcomeElement',
      {
        opacity: 0,
        duration: 1,
        onComplete: () => setShowWelcome(false),
      },
      1.2
    );
    mainTimeLine.to(
      '#questionsElement',
      {
        y: 98,
        duration: 1,
        ease: 'power1.inOut',
        onStart: () =>
          document.getElementById('headerElement').scrollIntoView({ behavior: 'smooth' }), // eslint-disable-line no-undef
      },
      1
    );
    mainTimeLine.from(
      '#questionsColumn1',
      {
        x: '-50vw',
        duration: 1,
        ease: 'power1.inOut',
      },
      2
    );
    mainTimeLine.from(
      '#questionsColumn2',
      {
        x: '50vw',
        duration: 1,
        ease: 'power1.inOut',
      },
      2
    );
    mainTimeLine.from(
      '#questionsButton',
      {
        opacity: 0,
        duration: 1,
        ease: 'power2.in',
      },
      2
    );

    resultsTimeLine.to('#questionsElement', {
      y: -1160,
      duration: 2,
      ease: 'slow(0.3, 0.4, false)',
      onStart: () => setShowHeader(false),
    });
    resultsTimeLine.from(
      '#resultsElement',
      {
        x: '-100vw',
        opacity: 0,
        duration: 1.5,
        ease: 'power1.inOut',
        onStart: () => {
          setShowResults(true);
          toWindowTop(false);
        },
      },
      1.7
    );

    mainTimeLineRef.current = mainTimeLine;
    resultsTimeLineRef.current = resultsTimeLine;
  }, []);

  return (
    <>
      {screenWidth < 769 ? (
        <Content>
          <Header show={showHeader} />
          <WelcomeSection show={showWelcome} onStart={handleStart} />
          <QuestionsSection show={showQuestions} onSubmit={handleSubmit} />
          <ResultsSection show={showResults} result={result} onStartOver={handleStartOver} />
        </Content>
      ) : (
        <GeneralMessage>Please open this experience on your mobile device.</GeneralMessage>
      )}
    </>
  );
};

const Content = styled.div`
  position: relative;
`;

const GeneralMessage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Container;
