import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gsap } from 'gsap';
import CheckIcon from '../../assets/images/check.svg';
import { colors } from '../../config/constants';
import { pxToRem } from '../../utils';

const Option = ({ option, active, onClick }) => {
  const timeLineRef = useRef();

  useEffect(() => {
    if (active) {
      timeLineRef.current.play();
    } else if (timeLineRef.current) {
      timeLineRef.current.reverse();
    }
  }, [active]);

  useEffect(() => {
    const timeLine = gsap.timeline({ paused: true });

    timeLine.fromTo(
      `#option-${option.id}`,
      {
        opacity: 0,
        scale: 0.1,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.2,
        ease: 'power1.inOut',
      }
    );
    timeLine.fromTo(
      `#optionIcon-${option.id}`,
      {
        opacity: 1,
        scale: 1,
      },
      {
        opacity: 0,
        scale: 0.2,
        duration: 0.2,
        ease: 'power1.in',
      },
      0
    );
    timeLine.fromTo(
      `#optionActiveIcon-${option.id}`,
      {
        opacity: 0,
        scale: 0.2,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.2,
        ease: 'power1.in',
      },
      0
    );

    timeLineRef.current = timeLine;
  }, []);

  return (
    <OptionContainer onClick={() => onClick(option.id)}>
      <DefaultOption>
        <IconWrapper>
          <Icon id={`optionIcon-${option.id}`} src={option.icon} alt="option icon" />
          <IconActive id={`optionActiveIcon-${option.id}`} src={CheckIcon} alt="check icon" />
        </IconWrapper>
        <OptionTextWrapper>
          <OptionText>{option.text}</OptionText>
        </OptionTextWrapper>
      </DefaultOption>
      <ActiveOption id={`option-${option.id}`} />
    </OptionContainer>
  );
};

const OptionContainer = styled.button`
  position: relative;
  width: 100%;
  height: 170px;
  background-color: white;
  padding: 0;
  margin: 0;
  border: 0;
  margin-bottom: 10px;
`;

const DefaultOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  border: 1px solid ${colors.gray2};
  background-color: ${colors.white};
  padding: 14px 10px 5px;
  height: 100%;
  box-sizing: border-box;
`;

const ActiveOption = styled(DefaultOption)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.green1};
  background-color: rgba(90, 188, 124, 0.2);
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const IconActive = styled.img`
  position: absolute;
  width: 50px;
  height: 50px;
`;

const OptionTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const OptionText = styled.p`
  color: ${colors.gray3};
  font-family: 'AvenirNextforINTUITMedium';
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(18)};
  margin: 0;
`;

Option.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number,
    icon: PropTypes.string,
    text: PropTypes.string,
  }),
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

Option.defaultProps = {
  option: {},
  active: false,
  onClick: () => {},
};

export default Option;
