import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../config/constants';
import { pxToRem } from '../../utils';

const Button = ({ id, text, onClick, disabled }) => (
  <ButtonWrapper id={id}>
    <StyledButton disabled={disabled} onClick={() => onClick()}>
      {text}
    </StyledButton>
  </ButtonWrapper>
);

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled.button`
  font-family: 'AvenirNextforINTUITDemi';
  background-color: ${props => props.disabled ? colors.gray2 : colors.blue1};
  color: ${colors.white};
  font-size: ${pxToRem(24)};
  border-radius: 15px;
  padding: 11px 26px;
  text-align: center;
  border: 0;
  margin: 0;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

Button.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  id: '',
  text: '',
  onClick: () => {},
  disabled: false,
};

export default Button;
