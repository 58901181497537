import React from 'react';
import Container from './components/Container';
import { Context } from './config/context';
import data from './data';

function App() {
  return (
    <Context.Provider value={data}>
      <Container />
    </Context.Provider>
  );
}

export default App;
