import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useData } from '../../config/context';
import Option from '../Option';
import Button from '../Button';
import { colors } from '../../config/constants';
import { pxToRem } from '../../utils';

const QuestionsSection = ({ show, onSubmit }) => {
  const { questions: data } = useData();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const onOptionClick = id => {
    const currentSelection = [...selectedOptions];
    const index = currentSelection.indexOf(id);
    if (index > -1) {
      currentSelection.splice(index, 1);
    } else {
      currentSelection.push(id);
    }
    setSelectedOptions(currentSelection);
  };

  const isActive = id => !!selectedOptions.find(optionId => optionId === id);

  const renderOptions = options =>
    options.map(option => (
      <Option
        key={option.id}
        option={option}
        onClick={onOptionClick}
        active={isActive(option.id)}
      />
    ));

  useEffect(() => {
    if (!show) {
      setSelectedOptions([]);
    }
  }, [show]);

  return (
    <Content id="questionsElement" show={show}>
      <Title>{data.title}</Title>
      <SelectText>{data.subtitle}</SelectText>
      <QuestionsContainer>
        <Column id="questionsColumn1">{renderOptions(data.options.column1)}</Column>
        <Column id="questionsColumn2">{renderOptions(data.options.column2)}</Column>
      </QuestionsContainer>
      <Button
        id="questionsButton"
        text="Submit"
        onClick={() => onSubmit(selectedOptions)}
        disabled={!selectedOptions.length}
      />
    </Content>
  );
};

const Content = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: 0;
  z-index: 2;
  padding: 22px 22px 70px;
`;

const Title = styled.h1`
  color: ${colors.gray1};
  font-family: 'AvenirNextforINTUITBold';
  font-weight: normal;
  font-size: ${pxToRem(23)};
  line-height: ${pxToRem(26)};
  text-align: center;
  margin: 0;
  margin-bottom: 24px;
`;

const SelectText = styled.h2`
  color: ${colors.gray1};
  font-family: 'AvenirNextforINTUITMedium';
  font-weight: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(26)};
  text-align: center;
  margin: 0;
  margin-bottom: 21px;
`;

const QuestionsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const Column = styled.div`
  width: 50%;
`;

QuestionsSection.propTypes = {
  onSubmit: PropTypes.func,
  show: PropTypes.bool,
};

QuestionsSection.defaultProps = {
  onSubmit: () => {},
  show: false,
};

export default QuestionsSection;
