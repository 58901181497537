import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logoImage from '../../assets/images/logo.png';
import { colors } from '../../config/constants';

const Header = ({ show }) => (
  <StyledHeader id="headerElement" show={show}>
    <Logo src={logoImage} alt="logo" />
  </StyledHeader>
);

const StyledHeader = styled.header`
  display: ${({ show }) => (show ? 'block' : 'none')};
  background-color: ${colors.blue1};
  padding: 32px 33px 14px;
  position: relative;
  z-index: 3;
`;

const Logo = styled.img`
  width: 218px;
`;

Header.propTypes = {
  show: PropTypes.bool,
};

Header.defaultProps = {
  show: true,
};

export default Header;
