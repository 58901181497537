import JobIcon from './assets/images/options/job.svg';
import HomeIcon from './assets/images/options/home.svg';
import DeductionsIcon from './assets/images/options/deductions.svg';
import StockIcon from './assets/images/options/stock.svg';
import SelfEmployedIcon from './assets/images/options/self-employed.svg';
import ChildrenIcon from './assets/images/options/children.svg';
import CharityIcon from './assets/images/options/charity.svg';
import TaxesIcon from './assets/images/options/taxes.svg';
import RentalIcon from './assets/images/options/rental.svg';
import BusinessIcon from './assets/images/options/business.svg';
import BasicImage from './assets/images/products/basic.jpg';
import DeluxeImage from './assets/images/products/deluxe.jpg';
import DeluxeStateImage from './assets/images/products/deluxeState.jpg';
import PremierImage from './assets/images/products/premier.jpg';
import HomeBusinessImage from './assets/images/products/homeBusiness.jpg';
import BusinessImage from './assets/images/products/business.jpg';

export const options = {
  job: {
    id: 1,
    icon: JobIcon,
    text: 'I have a job and received a W-2',
  },
  children: {
    id: 2,
    icon: ChildrenIcon,
    text: 'I have children or dependents',
  },
  home: {
    id: 3,
    icon: HomeIcon,
    text: 'I own a home',
  },
  charity: {
    id: 4,
    icon: CharityIcon,
    text: 'I donated over $300 to charity',
  },
  deductions: {
    id: 5,
    icon: DeductionsIcon,
    text: 'I want to maximize deductions and credits',
  },
  taxes: {
    id: 6,
    icon: TaxesIcon,
    text: 'I need to file state taxes',
  },
  stock: {
    id: 7,
    icon: StockIcon,
    text: 'I sold stock',
  },
  rental: {
    id: 8,
    icon: RentalIcon,
    text: 'I own rental property',
  },
  selfEmployed: {
    id: 9,
    icon: SelfEmployedIcon,
    text: "I'm self-employed (contractor, freelancer or consultant)",
  },
  business: {
    id: 10,
    icon: BusinessIcon,
    text: 'I own a small business',
  },
};

const data = {
  welcome: {
    title: 'Welcome!',
    text: 'Let’s find the right TurboTax product for you.',
    note: 'Not all TurboTax products may be available at this retailer and are subject to inventory stock and availability.',
  },
  questions: {
    title: 'Which product is right for you?',
    subtitle: 'Select all that apply and hit submit:',
    options: {
      column1: [options.job, options.home, options.deductions, options.stock, options.selfEmployed],
      column2: [options.children, options.charity, options.taxes, options.rental, options.business],
    },
  },
  results: {
    title: 'Based on your selections:',
    options: {
      basic: {
        image: BasicImage,
        text: 'We recommend <b>TurboTax Basic</b> to cover your simple taxes.',
      },
      deluxe: {
        image: DeluxeImage,
        text: 'We recommend <b>TurboTax Deluxe</b> to maximize deductions.',
      },
      deluxeState: {
        image: DeluxeStateImage,
        text: 'We recommend <b>TurboTax Deluxe with State</b> to maximize deductions and file state taxes.',
      },
      premier: {
        image: PremierImage,
        text: 'We recommend <b>TurboTax Premier</b> to cover your investments and rental property.',
      },
      homeBusiness: {
        image: HomeBusinessImage,
        text: 'We recommend <b>TurboTax Home & Business</b> to cover both your personal and self-employment taxes.',
        footnote1:
          'If your business is a partnership, S Corporation, C Corporation, multi-member LLC, trust or estate, we recommend TurboTax Business to cover your taxes. (State sold separately.)',
        footnote2:
          'TurboTax Business customers also use TurboTax   Premier for their personal tax returns.',
        footnoteImage: BusinessImage,
      },
    },
  },
};

export default data;
