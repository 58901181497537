import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useData } from '../../config/context';
import Button from '../Button';
import { colors } from '../../config/constants';
import { pxToRem } from '../../utils';

const ResultsSection = ({ show, result, onStartOver }) => {
  const { results: data } = useData();
  const [currentResult, setCurrentResult] = useState(data.options.basic);

  const setResultData = useCallback(() => {
    if (result) {
      switch (result) {
        case 'basic':
          setCurrentResult(data.options.basic);
          break;
        case 'deluxe':
          setCurrentResult(data.options.deluxe);
          break;
        case 'deluxeState':
          setCurrentResult(data.options.deluxeState);
          break;
        case 'premier':
          setCurrentResult(data.options.premier);
          break;
        case 'homeBusiness':
          setCurrentResult(data.options.homeBusiness);
          break;
        default:
          setCurrentResult(data.options.basic);
          break;
      }
    }
  }, [result, data, setCurrentResult]);

  useEffect(() => {
    setResultData();
    return () => {};
  }, [setResultData]);

  return (
    <Content id="resultsElement" show={show}>
      <Title>{data.title}</Title>
      <ImageWrapper>
        <Image src={currentResult.image} alt="result" />
      </ImageWrapper>
      {!currentResult.footnote1 ? (
        <Text
          dangerouslySetInnerHTML={{
            __html: currentResult.text,
          }}
        />
      ) : (
        <Text2
          dangerouslySetInnerHTML={{
            __html: currentResult.text,
          }}
        />
      )}
      {currentResult.footnote1 && (
        <Footnote>
          <ImageSection>
            <FootnoteText1>{currentResult.footnote1}</FootnoteText1>
            <FootnoteImage src={currentResult.footnoteImage} alt="footnote" />
          </ImageSection>
          <FootnoteText2>{currentResult.footnote2}</FootnoteText2>
        </Footnote>
      )}
      <Button text="Start over" onClick={() => onStartOver()} />
    </Content>
  );
};

const Content = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  padding-bottom: 70px;
`;

const Title = styled.h1`
  color: ${colors.gray1};
  font-family: 'AvenirNextforINTUITBold';
  font-weight: normal;
  font-size: ${pxToRem(22)};
  line-height: ${pxToRem(26)};
  text-align: center;
  margin: 20px 0;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 70px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 400px;
`;

const Text = styled.p`
  color: ${colors.gray1};
  font-family: 'AvenirNextforINTUITMedium';
  font-size: ${pxToRem(26)};
  line-height: ${pxToRem(30)};
  text-align: center;
  margin: 20px 50px 48px;

  b {
    font-family: 'AvenirNextforINTUITBold';
    font-weight: normal;
  }
`;

const Text2 = styled(Text)`
  margin: 20px 35px 28px;
`;

const Footnote = styled.div`
  border-top: 2px solid ${colors.gray2};
  padding-top: 20px;
  margin: 0 30px 28px;
`;

const ImageSection = styled.div`
  display: flex;
`;

const FootnoteText1 = styled.p`
  color: ${colors.gray4};
  font-family: 'AvenirNextforINTUITRegular';
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  text-align: left;
  margin: 0;
  margin-right: 30px;
`;

const FootnoteText2 = styled(FootnoteText1)`
  margin-right: 0;
  margin-top: 20px;
`;

const FootnoteImage = styled.img`
  width: 72px;
  height: 100%;
`;

ResultsSection.propTypes = {
  result: PropTypes.string,
  onStartOver: PropTypes.func,
  show: PropTypes.bool,
};

ResultsSection.defaultProps = {
  result: 'basic',
  onStartOver: () => {},
  show: false,
};

export default ResultsSection;
