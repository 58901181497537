export const colors = {
  white: '#fff',
  blue1: '#005fc5',
  gray1: '#414042',
  gray2: '#b2b1b1',
  gray3: '#231f20',
  gray4: '#1a1818',
  green1: '#5abc7c',
};

export default {
  colors,
};
