import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useData } from '../../config/context';
import Button from '../Button';
import AllProductsImage from '../../assets/images/all-products.png';
import { colors } from '../../config/constants';
import { pxToRem } from '../../utils';

const WelcomeSection = ({ show, onStart }) => {
  const { welcome: data } = useData();

  return (
    <Content id="welcomeElement" show={show}>
      <Title>{data.title}</Title>
      <AllProductsWrapper>
        <AllProducts src={AllProductsImage} alt="all products" />
      </AllProductsWrapper>
      <TextWrapper>
        <Text>{data.text}</Text>
      </TextWrapper>
      <Button id="welcomeButton" text="Start now" onClick={() => onStart()} />
      <NoteWrapper id="welcomeNoteElement">
        <Note>{data.note}</Note>
      </NoteWrapper>
    </Content>
  );
};

const Content = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  overflow: hidden;
  position: relative;
  z-index: 3;
  opacity: 0;
`;

const Title = styled.h1`
  font-family: 'AvenirNextforINTUITBold';
  font-weight: normal;
  color: ${colors.gray1};
  font-size: ${pxToRem(40)};
  line-height: ${pxToRem(40)};
  text-align: center;
  margin: 65px 0;
`;

const AllProductsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 30px;
`;

const AllProducts = styled.img`
  width: 100%;
  height: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 36px 0 32px;
`;

const Text = styled.p`
  width: 55%;
  color: ${colors.gray1};
  font-family: 'AvenirNextforINTUITRegular';
  font-size: ${pxToRem(28)};
  line-height: ${pxToRem(34)};
  text-align: center;
  margin: 0;
`;

const NoteWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 60px 95px;
`;

const Note = styled.p`
  color: ${colors.gray4};
  font-family: 'AvenirNextforINTUITRegular';
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  text-align: center;
`;

WelcomeSection.propTypes = {
  onStart: PropTypes.func,
  show: PropTypes.bool,
};

WelcomeSection.defaultProps = {
  onStart: () => {},
  show: true,
};

export default WelcomeSection;
